@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100vh;
}

.student-page-topper-container {
  clip-path: polygon(100% 0%, 100% 0, 100% 100%, 0 100%, 0 100%);
}

/* Student Sort Slider */
.slider-container {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  position: relative;
  width: 200px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #f1f5f9;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #11ccea;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #fff;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 200px;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

g.xAxis line {
  stroke: #0cb2da;
  stroke-dasharray: 1 1;
}

g.xAxis path {
  stroke-width: 4px;
  stroke: #0cb2da;
}

g.xAxis {
  font-size: 0.9rem;
  stroke: #0cb2da;
  font-weight: normal;
  letter-spacing: 0.2rem;
}

g.yAxis line {
  stroke: #0cb2da;
  stroke-dasharray: 1 1;
}

g.yAxis path {
  stroke-width: 4px;
  stroke: #0cb2da;
}

g.yAxis {
  font-size: 0.9rem;
  /* stroke: #0cb2da; */
  color: #636363;
  font-weight: semi-bold;
  letter-spacing: 0.2rem;
}

@media (max-height: 725px) {
  .desktop-nav-item {
    @apply py-2 text-base;
  }
}

@media (max-height: 630px) {
  .desktop-nav-item {
    @apply py-1 text-base;
  }
}

@media (max-height: 700px) {
  .slider-label {
    @apply mb-3 text-sm;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    height: 14px;
    width: 14px;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    height: 14px;
    width: 14px;
  }

  .slider-height {
    @apply h-4;
  }
}

.pass-percent-overlay {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 1) 68%,
    rgba(255, 255, 255, 1) 74%,
    rgba(255, 255, 255, 0) 100%
  );
}

/* mask for the minimal percent chart */
.minimal-mask {
  clip-path: circle(48.2% at 50% 50%);
}

/* tier pyramid for school details page */
.tier-pyramid {
  clip-path: polygon(100% 0, 0 0, 50% 100%);
}
